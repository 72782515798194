import React from 'react'
import CompaniesStatus from './graphs/CompaniesStatus'
import CompoundMetrics from './graphs/CompoundMetrics'
import FoundedYear from './graphs/FoundedYear'
import FundingGraph from './graphs/Funding'
import IndustriesCloud from './graphs/IndustriesCloud'
import TopCountries from './graphs/TopCountries'
import USTrafficMetrics from './graphs/USTrafficMetrics'
import { Modal } from 'react-bootstrap'

interface IProps {
  onHide: () => void
  show: boolean
}

class StatisticsModal extends React.Component<IProps> {
  render() {
    return (
      <Modal {...this.props} show={this.props.show} onHide={this.props.onHide} dialogClassName="filters-statistics" data-testid="modal">
        <Modal.Body>
          <div className="graphs">
            <div className="graph-container column-1">
              <FoundedYear />
              <IndustriesCloud />
            </div>
            <div className="graph-container column-2">
              <CompaniesStatus />
              <USTrafficMetrics />
              <CompoundMetrics />
            </div>
            <div className="graph-container column-3">
              <FundingGraph />
              <TopCountries />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default StatisticsModal
