import React from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Loader from '../../../../../components/Loader'
import { Bubble } from 'react-chartjs-2'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'
import { getStatistics } from '../../../../../adapters/companies'
import { percentage, percentageFormatted } from '../../../../../utils/calculations'
import { Chart } from 'chart.js'
import { htmlLegendPlugin } from '../../../../../utils/graphLegend'

Chart.register(ChartDataLabels)

interface Statistics {
  quantity: string
  quantityRaw: number
}

interface FoundedYearType {
  pastSevenYearsAndMore: Statistics
  blank: Statistics
  pastThreeYears: Statistics
  pastFiveYears: Statistics
}

interface IState {
  foundedYear?: FoundedYearType
  legend: any
}

class FoundedYear extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext

  constructor(props) {
    super(props)
    this.state = {
      foundedYear: undefined,
      legend: undefined,
    }
  }

  async componentDidMount() {
    const foundedYear = await getStatistics('founded_year')
    this.setState({ ...this.state, foundedYear })
  }

  render() {
    const options: chartjs.ChartOptions = {
      scales: {
        y: {
          title: {
            display: true,
            text: 'Percentage',
            color: '#999',
          },
        },
        x: {
          title: {
            display: true,
            text: 'Number of Companies',
            color: '#999',
          },
        },
      },
      plugins: {
        datalabels: {
          color: 'white',
          labels: {
            quantity: {
              font: {
                family: '"Gotham Book", sans-serif',
                size: 13,
              },
              formatter: function (value, { dataset }) {
                return `${dataset.quantity}/${dataset.percentage}`
              },
              textStrokeColor: 'black',
              textStrokeWidth: 1.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        htmlLegend: {
          containerID: 'legend-container',
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
    }

    let content: JSX.Element | JSX.Element[] = <Loader />
    if (this.state.foundedYear && this.context.companiesCount) {
      const { foundedYear } = this.state
      const datasetsArr = [
        {
          dataset: 'pastThreeYears',
          label: 'past 3 years',
          backgroundColor: 'rgb(31, 48, 66)',
        },
        {
          dataset: 'pastFiveYears',
          label: 'past 5 years',
          backgroundColor: '#E68E00',
        },
        {
          dataset: 'pastSevenYearsAndMore',
          label: 'past +7 years',
          backgroundColor: 'rgb(190, 80, 80)',
        },
        {
          dataset: 'blank',
          label: 'blank founding year',
          backgroundColor: 'rgb(66, 105, 150)',
        },
      ]

      const datasets = datasetsArr.map(({ dataset: datasetName, label, backgroundColor }) => {
        const quantityRaw = foundedYear[datasetName]?.quantityRaw || 0
        const quantity = foundedYear[datasetName]?.quantity || 0
        const percentageRaw = percentage(quantityRaw, this.context.companiesCount.countRaw)
        const percentageParsed = percentageFormatted(quantityRaw, this.context.companiesCount.countRaw)
        const data = [{ x: quantityRaw, y: percentageRaw, r: percentageRaw }]

        return { quantity, percentage: percentageParsed, label, data, backgroundColor }
      })

      content = (
        <>
          <h2>FOUNDED YEAR</h2>
          <Bubble data={{ datasets }} height={220} width={311} options={options} plugins={[ChartDataLabels, htmlLegendPlugin]} />
          <div id="legend-container"></div>
        </>
      )
    }

    return (
      <div className="founded-year" data-testid="founded-year">
        {content}
      </div>
    )
  }
}

export default FoundedYear
