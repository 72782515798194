import React, { useEffect, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import { getCompleteCompanyInfo } from '../adapters/companies'

export default function CompaniesTable({
  columns,
  data,
  pagination = 20,
  sortBy = null,
  className = '',
  showHeader = true,
  noDataMsg = 'No data available',
  refreshCompany,
}: {
  columns: any[]
  data: any[]
  pagination: number
  sortBy?: any
  refreshCompany: (companyId: number) => void
  className?: string
  showHeader?: boolean
  noDataMsg?: string | JSX.Element
}) {
  const [activeCompanyId, setActiveCompanyId] = useState(null)
  const [activeCompany, setActiveCompany] = useState(null)

  const tableOptions = { columns, data, ...(sortBy && { initialState: { sortBy } }) }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableOptions, useSortBy)
  const firstPageRows = rows.slice(0, pagination)

  const tbodyRef = React.useRef(null)

  const handleClick = async (event, row) => {
    const companyId = row.original.id
    setActiveCompanyId(companyId)
    const company = await getCompleteCompanyInfo(companyId)
    setActiveCompany(company)
    window.fillCommonElements(company)
  }

  const handleKeyDown = async (event) => {
    const currentRow = tbodyRef.current?.children.namedItem(activeCompanyId)
    if (!currentRow) {
      return
    }

    let nextRow: any
    let isNotTextInput: boolean
    const updateActiveCompany = async (event, row) => {
      event.preventDefault()
      if (!row) {
        return
      }
      await refreshCompany(activeCompanyId)
      const companyId = row.id
      await setActiveCompanyId(companyId)

      const company = await getCompleteCompanyInfo(companyId)
      await setActiveCompany(company)
      window.fillCommonElements(company)

      row.focus()
    }

    switch (event.keyCode) {
      case 32:
        window.toggleCompanyModal()
        $('#big-card-modal').one('hidden.bs.modal', async () => {
          if (!activeCompanyId) {
            return
          }
          await refreshCompany(activeCompanyId)
        })
        break
      case 38:
        nextRow = currentRow?.previousElementSibling
        await updateActiveCompany(event, nextRow)
        break
      case 40:
        nextRow = currentRow?.nextElementSibling
        await updateActiveCompany(event, nextRow)
        break
      default:
        isNotTextInput = event.target.localName !== 'input' && event.target.localName !== 'textarea'
        if (isNotTextInput && activeCompany) {
          window.setHotKeys(event, activeCompany)
        }
        break
    }
  }

  useEffect(() => {
    const companyModal = document.getElementById('big-card-modal')
    if (!companyModal) {
      return
    }
    companyModal.addEventListener('keydown', handleKeyDown)
    return () => {
      companyModal?.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <>
      <table className={`table-lists table-condensed dataTable ${className}`} {...getTableProps()}>
        {showHeader && (
          <thead className="table-lists-header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className={`${i === 0 ? '' : 'text-center'} ${column.isSorted ? (column.isSortedDesc ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    {column.disableSortBy ? null : <div className="sort-indicator" />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()} ref={tbodyRef} onKeyDown={handleKeyDown}>
          {firstPageRows.length ? (
            firstPageRows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={(event: React.MouseEvent) => {
                    handleClick(event, row)
                  }}
                  id={row.original.id}
                  key={row.original.id}
                  tabIndex={0}>
                  {row.cells.map((cell) => {
                    return (
                      <th
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                          },
                        ])}>
                        {cell.render('Cell')}
                      </th>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <th colSpan={100} className="empty">
                {noDataMsg}
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}
