import React, { Component } from 'react'
import { Media } from 'react-bootstrap'
import { chunk, round } from 'lodash'

import './RecentNewsTable.scss'
import Table from '../../../components/Table'
import Loader from '../../../components/Loader'

interface IProps {
  companies?: any
  loading: boolean
}

interface IState {
  companies?: any[]
  loading: boolean
}

class RecentNewsTable extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      companies: props.companies,
      loading: props.loading,
    }
  }

  static getDerivedStateFromProps(nextProps, _prevState) {
    return {
      companies: nextProps.companies,
      loading: nextProps.loading,
    }
  }

  render() {
    const nameCellFormatter = (props: any) => {
      const company = props.cell.row.original
      return (
        <Media className="company-logo">
          <Media.Left>
            <a href={`//${company.domain_name}`} target="_blank" rel="noreferrer">
              <img
                width={32}
                height={32}
                src={company.logo}
                alt={company.name}
                className="img-responsive img-rounded"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = ''
                  currentTarget.alt = ''
                }}
              />
            </a>
          </Media.Left>
          <Media.Body>
            <Media.Heading>
              <a href={`/companies/${company.id}`} target="_blank" rel="noreferrer">
                <p className="company-name">{company.name}</p>
                <p className="recent-news-date">{company.recent_news.posted_on}</p>
              </a>
            </Media.Heading>
            <a href={company.recent_news?.url} target="_blank" rel="noreferrer">
              <p className="recent-news-title">{company.recent_news.title}</p>
            </a>
          </Media.Body>
        </Media>
      )
    }

    const columns = [
      {
        Header: '',
        accessor: 'id',
        Cell: nameCellFormatter,
      },
    ]
    if (this.state.loading) {
      return <Loader />
    }
    if (!this.state.companies) {
      return (
        <p className="no-watchlist">
          Please create or choose a <a href={`/lists/`}>dynamic list</a> to see recent news in your Salesforce Watchlist.
        </p>
      )
    }
    const companiesWithNews = this.state.companies.filter((company) => !!company.recent_news)
    const companiesChunked = chunk(companiesWithNews, round(companiesWithNews.length / 2))
    if (!companiesChunked.length) {
      return <p className="no-watchlist">There are no recent news associated with the current companies shown in your Salesforce Watchlist.</p>
    }
    return (
      <>
        {companiesChunked[0]?.length && <Table columns={columns} data={companiesChunked[0]} pagination={3} className="recent-news-table" showHeader={false} />}
        {companiesChunked[1]?.length && <Table columns={columns} data={companiesChunked[1]} pagination={3} className="recent-news-table" showHeader={false} />}
      </>
    )
  }
}

export default RecentNewsTable
