import React from 'react'
import Loader from '../../../../../components/Loader'
import { getStatistics } from '../../../../../adapters/companies'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'
import { TagCloud } from 'react-tagcloud'

interface Statistics {
  text: string
  value: number
}

interface IState {
  statistics?: Statistics[]
}

class IndustriesCloud extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext
  constructor(props) {
    super(props)
    this.state = {
      statistics: undefined,
    }
  }

  async componentDidMount() {
    const statistics = await getStatistics('top_industries')
    this.setState({ ...this.state, statistics })
  }

  render() {
    let content = <Loader />
    if (this.state.statistics) {
      content = (
        <>
          <h2>TOP 7 INDUSTRIES</h2>
          <div className="industries-cloud-container">
            <TagCloud minSize={10} maxSize={16} tags={this.state.statistics} />
          </div>
        </>
      )
    }

    return (
      <div className="industries" data-testid="industries">
        {content}
      </div>
    )
  }
}

export default IndustriesCloud
