import ReactOnRails from 'react-on-rails'

import FiltersStatistics from '../react/pages/companies/filters/FiltersStatistics'
import MyFavoriteSourcingLists from '../react/pages/home/dashboard/MyFavoriteSourcingLists'
import MyWatchlists from '../react/pages/home/dashboard/MyWatchlists'

ReactOnRails.register({
  FiltersStatistics,
  MyFavoriteSourcingLists,
  MyWatchlists,
})
