import React from 'react'
import Loader from '../../../../../components/Loader'
import { getStatistics } from '../../../../../adapters/companies'
import { percentageFormatted } from '../../../../../utils/calculations'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'

interface Statistics {
  quantity: string
  quantityRaw: string
}

interface IState {
  statistics?: Statistics
}

class USTrafficMetrics extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext

  constructor(props) {
    super(props)
    this.state = {
      statistics: undefined,
    }
  }

  async componentDidMount() {
    const statistics = await getStatistics('us_traffic')
    this.setState({ ...this.state, statistics })
  }

  render() {
    let content = <Loader />
    if (this.state.statistics && this.context.companiesCount) {
      content = (
        <>
          <h2>US TRAFFIC</h2>
          <div className="statistic-container">
            <span className="percentage">{percentageFormatted(this.state.statistics.quantityRaw, this.context.companiesCount.countRaw)}</span>
            <span className="number">({this.state.statistics.quantity})</span>
            <p className="description">companies out of total has US Traffic &gt;= 25%</p>
          </div>
        </>
      )
    }

    return (
      <div className="us-traffic" data-testid="us-traffic">
        {content}
      </div>
    )
  }
}

export default USTrafficMetrics
