import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import './Dashboard.scss'
import './MyFavoriteSourcingLists.scss'
import TopFavoritesListsTable from './TopFavoriteListsTable'

class MyFavoriteSourcingLists extends Component {
  render() {
    return (
      <div className="dashboard-section sourcing-lists">
        <div className="section-title">
          <h4>My Favorite Sourcing Lists</h4>
          <div className="buttons">
            <Button bsStyle="primary" href="/lists">
              View All
            </Button>
            <Button bsStyle="primary" href="/companies?from=build_sourcing_list">
              Build Sourcing List
            </Button>
          </div>
        </div>
        <TopFavoritesListsTable />
      </div>
    )
  }
}

export default MyFavoriteSourcingLists
