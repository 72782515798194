import React, { Component } from 'react'
import { Button, Nav, NavItem, OverlayTrigger, Tooltip } from 'react-bootstrap'

import './Dashboard.scss'
import './MyWatchlists.scss'
import MySalesforceTable from './MySalesforceTable'
import RecentNewsTable from './RecentNewsTable'
import { getWatchlist } from '../../../adapters/lists'
import { get } from '../../../adapters/companies'

interface IProps {}

interface Watchlist {
  id: number
  name: string
  companies: any[]
  isOwner: boolean
}

interface IState {
  salesforceList?: Watchlist
  loadingSalesforceList: boolean
  salesforceListVisible: boolean
}

class MyWatchlists extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      loadingSalesforceList: true,
      salesforceListVisible: true,
      salesforceList: undefined,
    }
  }

  async componentDidMount() {
    await this.setSalesforceListVisible(true)
    await this.setSalesforceList()
  }

  async setSalesforceList() {
    this.setState({ ...this.state, salesforceList: undefined, loadingSalesforceList: true })
    const salesforceList = await getWatchlist()
    this.setState({ ...this.state, salesforceList, loadingSalesforceList: false })
  }

  async refreshCompanies(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    const { salesforceListVisible } = this.state
    if (salesforceListVisible) {
      await this.setSalesforceList()
    }
  }

  setSalesforceListVisible(salesforceListVisible: boolean) {
    this.setState({ ...this.state, salesforceListVisible })
  }

  render() {
    const settingsButton = () => {
      let title, tooltip, href
      if (this.state.salesforceListVisible && this.state.salesforceList) {
        const { id: listId } = this.state.salesforceList
        title = 'SF Settings'
        tooltip = <Tooltip id="tooltip">Edit My Salesforce dynamic list filters to change companies displayed in the Salesforce Watchlist</Tooltip>
        href = `/lists/${listId}`
      } else {
        return ''
      }

      return (
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Button bsStyle="primary" href={href} className="settings-watchlist">
            {title}
          </Button>
        </OverlayTrigger>
      )
    }
    const shuffleButton = () => {
      const tooltip = <Tooltip id="tooltip">Shuffle the rows to see new companies in this view.</Tooltip>

      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <button className="list-bubble refresh-watchlist" onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.refreshCompanies(event)}>
            <span className="glyphicon glyphicon-random" />
          </button>
        </OverlayTrigger>
      )
    }

    const refreshCompanyOnSalesforceList = async (companyId: number) => {
      if (this.state.salesforceList) {
        const companies = this.state.salesforceList.companies
        const refreshedCompany = await get(companyId)
        const companyIndex = companies.findIndex((company) => company.id === companyId)
        companies[companyIndex] = refreshedCompany
        const salesforceList = { ...this.state.salesforceList, companies }
        this.setState({ salesforceList })
      }
    }

    const contentTable = (
      <div className="mysalesforce-container">
        <MySalesforceTable
          companies={this.state.salesforceList?.companies}
          loading={this.state.loadingSalesforceList}
          refreshCompany={refreshCompanyOnSalesforceList}
        />
      </div>
    )

    return (
      <>
        <div className="dashboard-section my-watchlists">
          <h4 className="section-title">My Watchlists</h4>
          {shuffleButton()}
          {settingsButton()}
          <Nav bsStyle="tabs" className="tabs">
            <NavItem
              onClick={() => {
                this.setSalesforceListVisible(true)
              }}
              active={this.state.salesforceListVisible}>
              My Salesforce
            </NavItem>
          </Nav>
          {contentTable}
        </div>
        <div className="dashboard-section recent-news" style={{ visibility: !this.state.salesforceListVisible ? 'hidden' : undefined }}>
          <h4 className="section-title">Recent News</h4>
          <div className="recent-news-container">
            <RecentNewsTable companies={this.state.salesforceList?.companies} loading={this.state.loadingSalesforceList} />
          </div>
        </div>
      </>
    )
  }
}

export default MyWatchlists
