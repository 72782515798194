export const percentage = (quantity, total): number => {
  if (!quantity || !total) {
    return 0
  }

  return Math.round((quantity / total) * 1000) / 10
}

export const percentageFormatted = (quantity, total): string => {
  if (!quantity || !total) {
    return '0%'
  }

  const percentage = Math.round((quantity / total) * 1000) / 10
  return `${percentage}%`
}
