import React from 'react'
import './TopCountries.scss'
import 'country-flag-icons/3x2/flags.css'
import Loader from '../../../../../components/Loader'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getStatistics } from '../../../../../adapters/companies'
import { percentageFormatted } from '../../../../../utils/calculations'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'

interface Statistics {
  alpha2: string
  country: string
  quantity: number
  quantityRaw: number
}

interface IState {
  statistics?: Statistics[]
}

class TopCountries extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext

  constructor(props) {
    super(props)
    this.state = {
      statistics: undefined,
    }
  }

  async componentDidMount() {
    const statistics = await getStatistics('top_countries')
    this.setState({ ...this.state, statistics })
  }

  render() {
    let content: JSX.Element | JSX.Element[] = <Loader />
    let title
    if (this.state.statistics && this.context.companiesCount) {
      title = <h2>TOP 5 COUNTRIES</h2>
      content = this.state.statistics.map(({ alpha2, country, quantity, quantityRaw }) => (
        <div className="country" key={alpha2}>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{country}</Tooltip>}>
            <span className={`flag:${alpha2}`}></span>
          </OverlayTrigger>
          <h4>{percentageFormatted(quantityRaw, this.context.companiesCount.countRaw)}</h4>
          <h5>{quantity}</h5>
        </div>
      ))
    }

    return (
      <div className="top-countries" data-testid="top-countries">
        {title}
        {content}
      </div>
    )
  }
}

export default TopCountries
