import React, { Component } from 'react'
import { Media } from 'react-bootstrap'

import './MySalesforceTable.scss'
import CompaniesTable from '../../../components/CompaniesTable'
import Loader from '../../../components/Loader'
import MissingCompanyLogo from '../../../assets/img/missing-company-logo.png'

interface IProps {
  companies?: any[]
  loading: boolean
  refreshCompany: (companyId: number) => void
}
interface IState {
  companies?: any[]
  loading: boolean
}

class MySalesforceTable extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      companies: props.companies,
      loading: props.loading,
    }
  }

  static getDerivedStateFromProps(nextProps, _prevState) {
    return {
      companies: nextProps.companies,
      loading: nextProps.loading,
    }
  }

  render() {
    const nameCellFormatter = (props: any) => {
      const company = props.cell.row.original
      const name = props.value
      return (
        <Media className="company-logo">
          <Media.Left>
            <img
              width={30}
              height={30}
              src={company.logo}
              alt={name}
              className="img-responsive img-rounded"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = MissingCompanyLogo
                currentTarget.alt = ''
              }}
            />
          </Media.Left>
          <Media.Body>
            <Media.Heading>
              <a href={`/companies/${company.id}`} target="_blank" rel="noreferrer">
                {name}
              </a>
            </Media.Heading>
            <a href={`//${company.domain_name}`} target="_blank" rel="noreferrer">
              {company.domain_name}
            </a>
          </Media.Body>
        </Media>
      )
    }
    const percentageHTML = (count: string, growth: string) => {
      if (!count || !growth) {
        return '-'
      }

      const growthInt = parseInt(growth)
      const icon = growthInt > 0 ? 'top' : 'bottom'
      const style = {
        color: growthInt > 0 ? '#32CD32' : 'red',
        opacity: growthInt == 0 ? '0' : '1',
      }

      return (
        <p>
          {count} <i style={style} className={`glyphicon glyphicon-triangle-${icon}`} /> <span className="percent-cell">{growth}</span> YoY
        </p>
      )
    }
    const employeeCountCellFormatter = (props: any) => {
      const employeeCount = props.value
      const employeeGrowth = props.cell.row.original.employee_count_quarterly_growth_yoy
      return percentageHTML(employeeCount, employeeGrowth)
    }

    const usVisitsCellFormatter = (props: any) => {
      const usVisitsCount = props.value
      const usVisitsGrowth = props.cell.row.original.us_visits_growth_yoy
      return percentageHTML(usVisitsCount, usVisitsGrowth)
    }

    const globalVisitsCellFormatter = (props: any) => {
      const globalVisitsCount = props.value
      const globalVisitsGrowth = props.cell.row.original.similarweb_current_3m_avg_visits_growth_yoy
      return percentageHTML(globalVisitsCount, globalVisitsGrowth)
    }

    const columns = [
      {
        Header: 'COMPANY',
        accessor: 'name',
        className: 'watchlist-name',
        Cell: nameCellFormatter,
      },
      {
        Header: 'EMPLOYEES',
        accessor: 'employee_count',
        className: 'watchlist-employee-count',
        Cell: employeeCountCellFormatter,
      },
      {
        Header: 'US VISITS',
        accessor: 'similarweb_us_visits_count',
        className: 'watchlist-us-visits',
        Cell: usVisitsCellFormatter,
      },
      {
        Header: 'GLOBAL VISITS',
        accessor: 'similarweb_visits',
        className: 'watchlist-global-visits',
        Cell: globalVisitsCellFormatter,
      },
    ]
    const noDataMsg = (
      <p>There are no companies in the dynamic list you've set as a watchlist. Please modify your filters to see companies in your watchlist.</p>
    )

    if (this.state.loading) {
      return <Loader />
    }
    if (!this.state.companies) {
      return (
        <p className="no-watchlist">
          Please create or choose a <a href={`/lists/`}>dynamic list</a> to see companies in your Salesforce Watchlist.
        </p>
      )
    }
    return (
      <CompaniesTable
        columns={columns}
        data={this.state.companies}
        pagination={6}
        className="mysalesforce-table"
        noDataMsg={noDataMsg}
        refreshCompany={this.props.refreshCompany}
      />
    )
  }
}

export default MySalesforceTable
