import { createContext } from 'react'

export type companiesCount = {
  countRaw: number
  count: string
  listStats: boolean
}

type CompaniesCountContextType = {
  companiesCount?: companiesCount
  setCompaniesCount: (count?: companiesCount) => void
}

export const CompaniesCountContext = createContext<CompaniesCountContextType>({ companiesCount: undefined, setCompaniesCount: () => undefined })
