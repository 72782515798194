import React from 'react'
import Loader from '../../../../../components/Loader'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'
import { getStatistics } from '../../../../../adapters/companies'
import { percentageFormatted } from '../../../../../utils/calculations'

interface Statistics {
  quantity: string
  quantityRaw: number
}

interface IState {
  statistics?: Statistics
}

class CompoundMetrics extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext
  constructor(props) {
    super(props)
    this.state = {
      statistics: undefined,
    }
  }

  async componentDidMount() {
    const statistics = await getStatistics('compound_metrics')
    this.setState({ ...this.state, statistics })
  }

  render() {
    let content = <Loader />
    if (this.state.statistics && this.context.companiesCount) {
      content = (
        <>
          <h2>COMPOUND METRICS</h2>
          <div className="statistic-container">
            <span className="percentage">{percentageFormatted(this.state.statistics.quantityRaw, this.context.companiesCount.countRaw)}</span>
            <span className="number">({this.state.statistics.quantity})</span>
            <p className="description">Founded in last 3 years AND &gt;=25% employee growth</p>
          </div>
        </>
      )
    }

    return (
      <div className="compound-metrics" data-testid="compound-metrics">
        {content}
      </div>
    )
  }
}

export default CompoundMetrics
