import axios, { AxiosResponse } from 'axios'

export function edit(listId: number): Promise<string> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  return axios
    .get(`/lists/${listId}/edit`, {
      headers: {
        Accept: 'application/javascript',
      },
      data: {},
    })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export function destroy(listId: number): Promise<void | AxiosResponse> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''

  return axios
    .delete(`/lists/${listId}`, {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .catch((error) => {
      console.log(error)
    })
}

export function dynamicUpdate(listId: number): Promise<void | AxiosResponse> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''

  return axios
    .patch(
      `/lists/${listId}/update_dynamic`,
      {},
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )
    .catch((error) => {
      console.log(error)
    })
}

export function getTopFavorites(): Promise<any> {
  return axios
    .get<any>('/lists/top_favorites', {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
      return []
    })
}

export function getWatchlist(maybes?: boolean): Promise<any> {
  return axios
    .get<any>(`/lists/dashboard_watchlist${maybes ? '?maybes=true' : ''}`, {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export function unfavorite(listId: number): Promise<void | AxiosResponse> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''

  return axios
    .post(
      `/lists/${listId}/dashboard_unfavorite`,
      {},
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )
    .catch((error) => {
      console.log(error)
    })
}
