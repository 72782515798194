import React from 'react'
import { useSortBy, useTable } from 'react-table'

export default function Table({
  columns,
  data,
  pagination = 20,
  sortBy = null,
  rowClickHandler = null,
  className = '',
  showHeader = true,
  noDataMsg = 'No data available',
}: {
  columns: any[]
  data: any[]
  pagination: number
  sortBy?: any
  rowClickHandler?: (event: React.MouseEvent, id: number) => void
  className?: string
  showHeader?: boolean
  noDataMsg?: string | JSX.Element
}) {
  const tableOptions = { columns, data, ...(sortBy && { initialState: { sortBy } }) }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(tableOptions, useSortBy)
  const firstPageRows = rows.slice(0, pagination)

  return (
    <table className={`table-lists table-condensed dataTable ${className}`} {...getTableProps()}>
      {showHeader && (
        <thead className="table-lists-header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  className={`${i === 0 ? '' : 'text-center'} ${column.isSorted ? (column.isSortedDesc ? 'sorting_desc' : 'sorting_asc') : 'sorting'}`}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  {column.disableSortBy ? null : <div className="sort-indicator" />}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>
        {firstPageRows.length ? (
          firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={(event: React.MouseEvent) => {
                  rowClickHandler && rowClickHandler(event, row.original.id)
                }}>
                {row.cells.map((cell) => {
                  return (
                    <th
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}>
                      {cell.render('Cell')}
                    </th>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr>
            <th colSpan={100} className="empty">
              {noDataMsg}
            </th>
          </tr>
        )}
      </tbody>
    </table>
  )
}
