import React, { Component } from 'react'

import styles from './Loader.module.scss'

class Loader extends Component {
  render() {
    return (
      <div className={styles.loader} id={'loader'}>
        <div />
        <div />
      </div>
    )
  }
}

export default Loader
