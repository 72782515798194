import axios, { AxiosResponse } from 'axios'

export function get(companyId: string | number): Promise<any> {
  return axios
    .get<any>(`/companies/${companyId}/dashboard_show`, {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .then((response) => {
      return response.data.data.company
    })
    .catch((error) => {
      console.log(error)
      return []
    })
}

export function getCompleteCompanyInfo(companyId: string | number): Promise<any> {
  return axios
    .get<any>(`/companies/${companyId}/detail`, {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
      return []
    })
}

export function getStatistics(statisticsType?: string): Promise<any> {
  const url = `/companies/filters_statistics` + window.location.search
  const type = document.querySelector('.grid-listing')?.getAttribute('data-type')
  const listId = document.querySelector('.companies-listing')?.getAttribute('data-list-id')
  return axios
    .get<any>(url, {
      headers: {
        Accept: 'application/json',
      },
      params: { statistics_type: statisticsType, type, list_id: listId },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
      return []
    })
}

export function refreshMaybeDate(companyId: string | number): Promise<void | AxiosResponse> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  return axios
    .post<any>(`/companies/${companyId}/dashboard_refresh_maybe_date`, {
      headers: {
        Accept: 'application/json',
      },
      data: {},
    })
    .catch((error) => {
      console.log(error)
    })
}

export function updateStatus(companyId: string | number, statusCd: number): Promise<string> {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') || ''
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  return axios
    .put(
      `/companies/update_status/?id=${companyId}&status_cd=${statusCd}`,
      {},
      {
        headers: {
          Accept: 'application/javascript',
        },
      }
    )
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}
