import React from 'react'
import Loader from '../../../../../components/Loader'
import iconNeutral from '../../../../../assets/img/icon-neutral.png'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'
import { getStatistics } from '../../../../../adapters/companies'

interface Statistics {
  maybe: string
  pass: string
  neutral: string
}

interface IState {
  statistics?: Statistics
}

class CompaniesStatus extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const statistics = await getStatistics('companies_status')
    this.setState({ ...this.state, statistics })
  }

  render() {
    let content = <Loader />
    if (this.state.statistics && this.context.companiesCount?.count) {
      content = (
        <>
          <h1>
            <span>{this.context.companiesCount.count}</span> Companies
          </h1>
          <div className="status-metric-container">
            <div className="passed">
              <h3>{this.state.statistics.pass || 0}</h3>
              <span className="icon-pass">
                <span className="path1" />
                <span className="path2" />
              </span>
              <h4>Passed: Too Early</h4>
            </div>
            <div className="neutral">
              <h3>{this.state.statistics.neutral || 0}</h3>
              <img src={iconNeutral} className="icon-neutral" alt="Neutral" />
              <h4>Neutral</h4>
            </div>
          </div>
        </>
      )
    }

    return (
      <div className="companies" data-testid="companies">
        {content}
      </div>
    )
  }
}

export default CompaniesStatus
