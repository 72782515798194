import React from 'react'
import { Chart as ChartJS, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import Loader from '../../../../../components/Loader'
import { CompaniesCountContext } from '../../../../../context/CompaniesCountContext'
import { getStatistics } from '../../../../../adapters/companies'
import { percentageFormatted } from '../../../../../utils/calculations'
import { htmlLegendPlugin } from '../../../../../utils/graphLegend'

ChartJS.register(...registerables, ChartDataLabels)

interface Datasets {
  quantity: string
  percentage: string
  label: string
  data: number[]
  backgroundColor: string
}

interface IState {
  legend?: string
  data?: {
    datasets: Datasets[]
  }
  labels?: string[]
}

class Funding extends React.Component<unknown, IState> {
  static contextType = CompaniesCountContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const funding = await getStatistics('funding')
    const companiesCountRaw = this.context.companiesCount?.countRaw

    const createDataset = (key: string, label: string, color: string) => {
      const quantityRaw = funding[key]?.quantityRaw || 0
      const quantity = funding[key]?.quantity || '0'
      const percentage = percentageFormatted(quantityRaw, companiesCountRaw)

      return { label, data: [quantityRaw], backgroundColor: color, quantity, percentage }
    }

    const data = {
      labels: ['Companies'],
      datasets: [createDataset('lessThanTenMillions', 'companies raised <$10M', '#1F3042'), createDataset('blank', 'companies blank funding', '#E68E00')],
    }

    this.setState({ ...this.state, data })
  }

  render() {
    const config: chartjs.ChartOptions = {
      indexAxis: 'y',
      layout: {
        padding: {
          left: 22,
          right: 22,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: 'white',
          labels: {
            quantity: {
              font: {
                family: '"Gotham Book", sans-serif',
                size: 13,
              },
              formatter: function (value, { dataset }) {
                return `${dataset.quantity}/${dataset.percentage}`
              },
              textStrokeColor: 'black',
              textStrokeWidth: 1.5,
            },
          },
        },
        htmlLegend: {
          containerID: 'legend-container-funding',
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      responsive: true,
      scales: {
        x: {
          display: false,
          stacked: true,
        },
        y: {
          display: false,
          stacked: true,
        },
      },
    }

    let content = <Loader />
    if (this.state.data) {
      content = (
        <>
          <h2>FUNDING</h2>
          <div className="funding-graph-container">
            <Bar data={this.state.data} options={config} plugins={[ChartDataLabels, htmlLegendPlugin]} />
          </div>
          <div id="legend-container-funding"></div>
        </>
      )
    }

    return (
      <div className="funding" data-testid="funding">
        {content}
      </div>
    )
  }
}

export default Funding
