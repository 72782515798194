import React, { Component } from 'react'
import StatisticsModal from './filtersStatistics/StatisticsModal'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { companiesCount, CompaniesCountContext } from '../../../context/CompaniesCountContext'

interface IState {
  companiesCount: companiesCount
  disableShowStats: boolean
  loading: boolean
  show: boolean
}

interface IProps {
  companiesCount: companiesCount
  disableShowStats: boolean
}

class FiltersStatistics extends Component<IProps, IState> {
  constructor(props, context) {
    super(props, context)

    this.handleButtonDisable = this.handleButtonDisable.bind(this)
    this.handleHide = this.handleHide.bind(this)
    this.handleShow = this.handleShow.bind(this)

    this.state = {
      companiesCount: { ...this.props.companiesCount, listStats: this.props.listStats },
      disableShowStats: this.props.disableShowStats,
      loading: false,
      show: false,
    }
  }

  componentDidMount() {
    window.addEventListener('reactCompaniesCountUpdated', this.handleButtonDisable)
  }

  componentWillUnmount() {
    window.removeEventListener('reactCompaniesCountUpdated', this.handleButtonDisable)
  }

  handleShow() {
    this.setState({ show: !this.state.show })
  }

  handleHide() {
    this.setState({ show: false })
  }

  handleButtonDisable(event) {
    const { companiesCount, disableShowStats, loading } = event.detail
    this.setState({ ...this.state, companiesCount, disableShowStats, loading })
  }

  render() {
    const showStatsButton = (
      <Button href="#" id="filters-statistics" onClick={this.handleShow} className="show-stats" bsStyle="link" disabled={this.state.disableShowStats}>
        Show Stats
      </Button>
    )
    let content
    if (this.state.loading) {
      content = (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Calculating...</Tooltip>}>
          {showStatsButton}
        </OverlayTrigger>
      )
    } else if (this.state.disableShowStats) {
      content = (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip">Stats are not available for lists or filtered results of &gt;1M companies due to performance.</Tooltip>}
          placement="bottom">
          {showStatsButton}
        </OverlayTrigger>
      )
    } else {
      content = showStatsButton
    }

    return (
      <>
        <div className="filters-statistics">
          <div className="buttons">
            <CompaniesCountContext.Provider
              value={{
                companiesCount: this.state.companiesCount,
                setCompaniesCount: (companiesCount: companiesCount) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    companiesCount,
                  }))
                },
              }}>
              <StatisticsModal onHide={this.handleHide} show={this.state.show}></StatisticsModal>
              {content}
            </CompaniesCountContext.Provider>
          </div>
        </div>
      </>
    )
  }
}

export default FiltersStatistics
