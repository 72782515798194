import React, { Component } from 'react'

import Table from '../../../components/Table'
import './TopFavoriteListsTable.scss'
import { destroy, dynamicUpdate, edit, getTopFavorites, unfavorite } from '../../../adapters/lists'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../../../components/Loader'

interface IProps {}

interface IState {
  lists: any[]
  loading: boolean
}

class TopFavoriteListsTable extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      lists: [],
      loading: true,
    }
  }

  async componentDidMount() {
    const lists: any[] = await getTopFavorites()
    this.setState({ lists, loading: false })
  }

  editHandler(event: React.MouseEvent<HTMLElement>, listId: number) {
    event.stopPropagation()
    edit(listId).then((data) => {
      // TODO: This should be a react modal for editing the list instead of jQuery code response
      // eslint-disable-next-line no-eval
      eval(data)
    })
  }

  destroyHandler(event: React.MouseEvent<HTMLElement>, listId: number) {
    event.stopPropagation()
    const confirmDestroyAlert = confirm('Are you sure you want to delete this list?')
    if (!confirmDestroyAlert) {
      return
    }

    destroy(listId).then(() => {
      const lists = this.state.lists.filter((list) => list.id !== listId)

      this.setState({ lists })
    })
  }

  unfavoriteHandler(event: React.MouseEvent<HTMLElement>, listId: number) {
    event.stopPropagation()
    unfavorite(listId).then(() => {
      const lists = this.state.lists.filter((list) => list.id !== listId)

      this.setState({ lists })
    })
  }

  updateDynamicHandler(event: React.MouseEvent<HTMLElement>, listId: number) {
    event.stopPropagation()
    dynamicUpdate(listId).then(() => {
      const updatedLists = this.state.lists.map((list) => {
        if (list.id === listId) {
          return { ...list, dynamic_updating: true }
        }
        return list
      })

      this.setState({ lists: updatedLists })
    })
  }

  render() {
    const favCellFormatter = (props: any) => {
      if (!props.value) {
        return ''
      }
      const tooltip = <Tooltip id="tooltip">Remove from favorites</Tooltip>
      const listId = props.cell.row.original.id

      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <div onClick={(event: React.MouseEvent<HTMLElement>) => this.unfavoriteHandler(event, listId)}>
            <i className="fa fa-star" />
          </div>
        </OverlayTrigger>
      )
    }

    const dateUpdateCellFormatter = (props: any) => {
      return props.cell.row.original.date_updated
    }

    const visibilityCellFormatter = (props: any) => {
      const peopleInitials = props.cell.row.original.people_initials
      const tooltip = <Tooltip id="tooltip">{peopleInitials}</Tooltip>

      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <i className={`fa fa-${props.value}`} />
        </OverlayTrigger>
      )
    }

    const notesCellFormatter = (props: any) => {
      const tooltip = <Tooltip id="tooltip">{props.value}</Tooltip>
      const noteTruncated = props.cell.row.original.note_truncated

      return (
        <OverlayTrigger placement="left" overlay={tooltip}>
          <p>{noteTruncated}</p>
        </OverlayTrigger>
      )
    }

    const listTypeCellFormatter = (props: any) => {
      if (!props.value) {
        return ''
      }
      const dynamicUpdating = props.cell.row.original.dynamic_updating
      const listId = props.cell.row.original.id

      return (
        <button className="list-bubble" onClick={(event: React.MouseEvent<HTMLElement>) => this.updateDynamicHandler(event, listId)}>
          <span className={`fa fa-refresh ${dynamicUpdating ? 'fa-spin' : ''}`} />
        </button>
      )
    }

    const actionsCellFormatter = (props: any) => {
      if (!props.value) {
        return ''
      }
      const listId = props.cell.row.original.id

      return (
        <>
          <button className="list-bubble destroy-list" onClick={(event: React.MouseEvent<HTMLElement>) => this.destroyHandler(event, listId)}>
            <span className="icon-delete" />
          </button>
          <button className="list-bubble edit-list" onClick={(event: React.MouseEvent<HTMLElement>) => this.editHandler(event, listId)}>
            <span className="glyphicon glyphicon-pencil" />
          </button>
        </>
      )
    }

    const columns = [
      {
        Header: 'FAV',
        accessor: 'favorited',
        Cell: favCellFormatter,
        className: 'list-favorite',
      },
      { Header: 'NAME', accessor: 'name', className: 'list-name' },
      {
        Header: '# COMPANIES',
        accessor: 'total_count',
        className: 'total-count',
      },
      {
        Header: '# NEUTRAL',
        accessor: 'neutral_count',
        className: 'neutral-count',
      },
      {
        Header: '# MAYBE',
        accessor: 'owner_maybe_count',
        className: 'maybe-count',
      },
      {
        Header: '# LEAD',
        accessor: 'lead_count',
        className: 'lead-count',
      },
      {
        Header: 'DATE UPDATED',
        accessor: 'updated_at',
        className: 'date-updated',
        Cell: dateUpdateCellFormatter,
      },
      {
        Header: 'DATE CREATED',
        accessor: 'date_created',
        className: 'date-created',
      },
      {
        Header: 'VISIBILITY',
        accessor: 'visibility_icon',
        Cell: visibilityCellFormatter,
        className: 'visibility',
      },
      {
        Header: 'DOWNLOAD SCREENSHOT',
        accessor: 'need_screenshot_parsed',
        className: 'need-screenshot',
      },
      {
        Header: 'NOTES',
        accessor: 'note',
        Cell: notesCellFormatter,
        className: 'note',
      },
      {
        Header: 'LIST TYPE',
        accessor: 'set_dynamic_at',
        Cell: listTypeCellFormatter,
        className: 'list-type',
        disableSortBy: true,
      },
      {
        Header: 'ACTIONS',
        accessor: 'owner',
        Cell: actionsCellFormatter,
        disableSortBy: true,
        className: 'list-actions',
      },
    ]

    if (this.state.loading) {
      return <Loader />
    }

    const sortBy = [{ id: 'updated_at', desc: true }]
    const rowClickHandler = (event: React.MouseEvent, listId: number) => {
      window.location.assign(`lists/${listId}`)
    }

    return (
      <div className="fixed-table-head">
        <Table columns={columns} data={this.state.lists} pagination={10} sortBy={sortBy} rowClickHandler={rowClickHandler} />
      </div>
    )
  }
}

export default TopFavoriteListsTable
